import React, { isValidElement } from "react"
import { TextBox } from "./text-box"

export interface ICardTitleProbs {
  title: string | JSX.Element
}

const CardTitle = ({ title }: ICardTitleProbs) => {
  if (title === null) {
    return null
  }

  if (isValidElement(title)) {
    return title
  }

  return (
    <div className="text-uppercase d-flex justify-content-center">
      <TextBox>{title}</TextBox>
    </div>
  )
}

export interface ICardProbs extends ICardTitleProbs {
  className?: string
  children?: any
}

export const Card = ({
  title = null,
  className = "",
  children,
}: ICardProbs) => (
  <div
    className="card bg-white"
    style={{
      backgroundColor: "#f8fffc",
      borderRadius: "0px",
      border: "unset",
      boxShadow: "10px 10px 10px #00000048",
      marginBottom: "30px",
    }}
  >
    <div
      className="card-body"
      style={{
        padding: "2.0rem",
      }}
    >
      <CardTitle title={title} />
      <div className={`${className}`}>{children}</div>
    </div>
  </div>
)
