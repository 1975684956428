import React from "react"

export const TextBox = ({ children }) => (
  <h4
    style={{
      padding: "0.25rem 1.2rem",
      border: "unset",
      backgroundColor: "#59a7a7",
      color: "#f8fffc",
      // fontSize: "20px",
      marginBottom: "20px",
    }}
  >
    {children}
  </h4>
)
