import React, { Fragment } from "react"
import { Row, Col } from "react-bootstrap"
import { IAuto, IAllCarProps } from "./interfaces"
import { AutoFormCard } from "./input-auto"

export const AutoCards = ({
  autos,
  setAutos,
  nutzung,
}: {
  autos: IAuto[]
  setAutos: any
  nutzung: IAllCarProps
}) => {
  const updateAuto = (index: number) => (fn: (auto: IAuto) => IAuto) => {
    const newAutos = [...autos]
    newAutos[index] = fn(newAutos[index])
    setAutos(newAutos)
  }

  return (
    <Row>
      {autos.map((auto, index) => (
        <Fragment key={index}>
          <Col key={index}>
            <AutoFormCard
              auto={auto}
              setAuto={updateAuto(index)}
              km={nutzung.km}
            ></AutoFormCard>
          </Col>
          {index < autos.length - 1 ? (
            <Col sm="12" className="mw-1 d-flex">
              <div
                className="align-self-center text-uppercase mx-auto"
                style={{
                  color: "#f8fffc",
                  fontSize: "22px",
                }}
              >
                vs.
              </div>
            </Col>
          ) : null}
        </Fragment>
      ))}
    </Row>
  )
}
