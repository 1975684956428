import React from "react"
import { Form, Row, Col, InputGroup } from "react-bootstrap"

import "../styles/slider.css"

export const FormSliderGroup = ({
  label,
  value,
  setValue,
  max,
  step = 1000,
  appendix = null,
}: {
  label: any
  value: number
  setValue: any
  max: any
  step: number
  appendix: string
}) => (
  <Form.Group>
    <Form.Label style={{ textTransform: "uppercase" }}>{label}</Form.Label>
    <Row>
      <Col
      style={{
        minWidth: "250px",
        maxWidth: "500px"
      }}
      >
        <Form.Control
          type="range"
          value={value}
          onChange={changeEvent => setValue(Number(changeEvent.target.value))}
          max={max}
          step={step}
          className="mb-4"
        />
      </Col>
      <Col 
      style={{
        minWidth: "150px",
        maxWidth: "200px"
      }}>
        <Input
          value={value}
          type="number"
          setValue={setValue}
          appendix={appendix}
          step={step}
        />
      </Col>
    </Row>
  </Form.Group>
)

export const Input = ({
  label = null,
  value,
  setValue,
  appendix,
  type = undefined,
  step=null
}) => (
  <Form.Group>
    {label ? <Form.Label>{label}</Form.Label> : null}
    <InputGroup
      style={{
        border: "4px solid #59a7a7",
      }}
    >
      <Form.Control
        style={{

        }}
        value={value}
        onChange={changeEvent => setValue(changeEvent.target.value)}
        type={type}
        step={step}
      />
      {appendix ? (
        <InputGroup.Append>
          <InputGroup.Text
            style={{
              paddingRight: "0 0.4rem 0 0",
              border: "unset",
              backgroundColor: "#ffffff",
              color: "#59a7a7",
              fontSize: "20px"
            }}
          >
            {appendix}
          </InputGroup.Text>
        </InputGroup.Append>
      ) : null}
    </InputGroup>
  </Form.Group>
)
