import { IAuto, IAllCarProps } from "./interfaces"
import { roundBy } from "../../helpers/range"


export const defaultNutzung: IAllCarProps = {
    km: 12000,
}

export const defaultEAuto: IAuto = {
    name: "Elektro-Auto",
    preisAuto: 40000,
    verbrauchPro100Km: 12.5,
    verbrauchTyp: "kWh",
    preisKraftstoff: 0.303,
}

export const defaultVerbrenner: IAuto = {
    name: "Verbrenner",
    preisAuto: roundBy(defaultEAuto.preisAuto, 1000),
    verbrauchPro100Km: 8,
    verbrauchTyp: "Liter",
    preisKraftstoff: 1.45,
}

export const getDefaultVerbrenner = (eAuto: IAuto): IAuto => ({
    name: "Verbrenner",
    preisAuto: roundBy(eAuto.preisAuto, 1000),
    verbrauchPro100Km: 8,
    verbrauchTyp: "Liter",
    preisKraftstoff: 1.45,
})