import { IAuto } from "../components/rechner/interfaces"


export const range = (start: number, end: number) => new Array(end - start + 1)
    .fill(undefined).map((_, i) => i + start)



export const kostenPa = (km: number, auto: IAuto): number => {
    return Math.round((km / auto.verbrauchPro100Km) * auto.preisKraftstoff)
}

export const kostenKummuliert = (km: number, auto: IAuto) => {
    const k = kostenPa(km, auto)
    const result = [auto.preisAuto + k]
    for (const i of range(1, 9)) {
        result.push(result[i - 1] + k)
    }
    return result
}

export const roundBy = (num, val) => (
    Math.round((num * 0.8) / val) * val
)