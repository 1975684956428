import React from "react"
import { range, kostenKummuliert } from "../../helpers/range"
import { Card } from "../card"

import "../../styles/table.css"
import { IAuto } from "./interfaces"
import { Table } from "../table"


export const ErgebnisTable = ({
  km,
  autos,
}: {
  km: number
  autos: IAuto[]
}) => (
  <Card title="Vergleichs-Tabelle">
    <div className="row justify-content-center">
      <div className="col-auto">
        <Table
          fixFirstColumn
          colnames={[null, ...range(1, 10).map(x => `${x}. Jahr`)]}
          data={autos.map(auto => [auto.name, ...kostenKummuliert(km, auto)])}
        />
      </div>
    </div>
  </Card>
)
