import React from "react"
import { Table as BTable } from "react-bootstrap"

export const Table = ({
  colnames,
  data,
  fixFirstColumn = false,
}: {
  colnames: string[]
  data: Array<Array<string | number>>
  fixFirstColumn?: boolean
}) => (
  <BTable
    size="md"
    style={{ display: "block", overflowX: "auto", whiteSpace: "nowrap" }}
  >
    <thead>
      <tr>
        {colnames.map((colname: string, i: number) => (
          <th key={i} className={fixFirstColumn ? "fixFirstColumn" : ""}>
            {colname}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map((row, rowIndex) => (
        <tr key={rowIndex}>
          {row.map((val, valIndex) => (
            <td
              key={valIndex}
              className={fixFirstColumn ? "fixFirstColumn" : ""}
            >
              {val}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </BTable>
)
