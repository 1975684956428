import React, { useState } from "react"
import { Row, Col } from "react-bootstrap"
import { FormSliderGroup, Input } from "../form-elements"
import { Card } from "../card"
import { kostenPa } from "../../helpers/range"
import { DropDown } from "./dropdown"
import { useStaticQuery, graphql } from "gatsby"
import { IAuto, IAllAutosJson } from "./interfaces"

export interface IInputAutoProbs {
  auto: IAuto
  setAuto: any //(fn: (auto: IAuto) => IAuto) => void
  km: number
}

export const AutoFormCard = ({ auto, setAuto, km }: IInputAutoProbs) => {
  const {
    allAutosJson: { nodes: autos },
  }: IAllAutosJson = useStaticQuery(graphql`
    query AllAutoQuery {
      allAutosJson(sort: { fields: name }) {
        nodes {
          name
          verbrauchPro100Km
          verbrauchTyp
          reichweite
          preisAuto
          url
        }
      }
    }
  `)

  const cardTitle =
    auto.verbrauchTyp !== "kWh" ? (
      auto.name
    ) : (
      <div className="text-uppercase d-flex justify-content-center">
        <DropDown
          title={auto.name}
          items={autos.map(auto => auto.name)}
          onSelect={name => {
            const { preisAuto, verbrauchPro100Km } = autos.find(
              a => a.name === name
            )
            setAuto(auto => ({ ...auto, name, preisAuto, verbrauchPro100Km }))
          }}
          onFilter={p => null}
        />
      </div>
    )

  return (
    <Card title={cardTitle}>
      <FormSliderGroup
        label="Anschaffungspreis"
        value={auto.preisAuto}
        setValue={(preisAuto: number) =>
          setAuto(auto => ({ ...auto, preisAuto }))
        }
        max={100000}
        step={1000}
        appendix="€"
      />
      <Row>
        <Col md="12" sm="12" lg="6">
          <Input
            label={`Preis je ${auto.verbrauchTyp}`}
            value={
              auto.preisKraftstoff * (auto.verbrauchTyp === "kWh" ? 100 : 1)
            }
            setValue={preisKraftstoff =>
              setAuto(p => ({
                ...p,
                preisKraftstoff:
                  preisKraftstoff / (auto.verbrauchTyp === "kWh" ? 100 : 1),
              }))
            }
            appendix={auto.verbrauchTyp === "kWh" ? "Cent" : "€"}
            type="number"
          />
        </Col>
        <Col md="12" sm="12" lg="6">
          <Input
            label="Verbrauch auf 100km"
            value={auto.verbrauchPro100Km}
            setValue={verbrauchPro100Km =>
              setAuto(p => ({
                ...p,
                verbrauchPro100Km,
              }))
            }
            appendix={auto.verbrauchTyp}
            type="number"
          />
        </Col>
      </Row>
      <div className="mt-4 text-uppercase">
        Gesamtkosten im Jahr: {kostenPa(km, auto)}€
      </div>
    </Card>
  )
}
