export const Header = ({ title=undefined }) => (
    <header className="container mt-5 mb-5">
      <div className="row justify-content-center">
        <div
          style={{
            padding: "1.5rem",
            border: "3px solid #dee2e6",
          }}
        >
          <div
            className="h1"
            style={{
              margin: "unset",
              color: "#ffffff",
              textTransform: "uppercase",
              overflow: "hidden",
            }}
          >
            <span
              style={{
                display: "inline-block",
                marginRight: "10px",
              }}
            >
              {title ? title[0] : "Wann lohnt sich ein "}
            </span>
            <span
              style={{
                boxShadow: "inset 0 0px 0 white, inset 0 -18px 0 #e2bf14",
                marginLeft: "-5px",
                paddingLeft: "5px",
                paddingRight: "3px",
                display: "inline-block",
              }}
            >
              {title ? title[1] : "Elektro Auto?"}
            </span>
          </div>
        </div>
      </div>
    </header>
  )