import React, { useState, useEffect } from "react"
import {
  Dropdown as BDropdown,
  FormControl,
} from "react-bootstrap"

export const DropDown = ({
  title,
  items,
  onSelect,
  onFilter,
}: {
  title: string
  items: string[]
  onFilter: (phrase: string) => void
  onSelect: (item: string) => void
}) => {
  const [phrase, setPhrase] = useState("")

  useEffect(() => {
    onFilter(phrase)
  }, [phrase, onFilter])

  return (
    <BDropdown onSelect={(e: string) => onSelect(e)}>
      <BDropdown.Toggle
        as="h4"
        variant="success"
        id="dropdown-basic"
        style={{
          cursor: "pointer",
          padding: "0.25rem 1.2rem",
          border: "unset",
          backgroundColor: "#59a7a7",
          color: "#f8fffc",
          marginBottom: "20px",
        }}
      >
        {title}
      </BDropdown.Toggle>

      <BDropdown.Menu>
        <FormControl
          autoFocus
          className="mx-auto dropdown-search"
          placeholder="Wähle ein Auto"
          onChange={e => {
            console.log(123);
            
            setPhrase(e.target.value)
            console.log(123);
            
          }}
          value={phrase}
        />
        {items
          .filter(
            (val: string) => !phrase || val.toLocaleLowerCase().includes(phrase)
          )
          .map((val: string) => (
            <BDropdown.Item key={val} eventKey={val}>
              {val}
            </BDropdown.Item>
          ))}
      </BDropdown.Menu>
    </BDropdown>
  )
}
