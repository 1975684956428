import React, { useState } from "react"
import { Row, Col } from "react-bootstrap"
import { Card } from "../card"
import { FormSliderGroup } from "../form-elements"
import { defaultNutzung, defaultEAuto, getDefaultVerbrenner } from "./defaults"
import { IAuto } from "./interfaces"
import { AutoCards } from "./auto-cards"
import { ErgebnisTable } from "./ergebnis-table"
import { ErgebnisChart } from "./ergebnis-chart"

export interface IRechnerProbs {
  auto?: IAuto
}

export const Rechner = ({ auto = {} as IAuto }: IRechnerProbs) => {
  const [nutzung, setNutzung] = useState(defaultNutzung)

  const [autos, setAutos] = useState([
    { ...defaultEAuto, ...auto },
    getDefaultVerbrenner({ ...defaultEAuto, ...auto }),
  ])

  return (
    <>
      <Row>
        <Col>
          <Card
            title="Kilometer pro Jahr"
            className="d-flex justify-content-center"
          >
            <div
              style={{
                margin: "auto",
              }}
            >
              <FormSliderGroup
                label="Wie viele Kilometer fahren Sie im Jahr?"
                value={nutzung.km}
                setValue={(km: number) => setNutzung(p => ({ ...p, km: km }))}
                max={40000}
                appendix="km"
                step={1000}
              />
            </div>
          </Card>
        </Col>
      </Row>
      <AutoCards autos={autos} setAutos={setAutos} nutzung={nutzung} />
      <ErgebnisChart km={nutzung.km} autos={autos} />
      <ErgebnisTable km={nutzung.km} autos={autos} />
      <div className="d-flex justify-content-center text-uppercase color-white">
        Preise und Verbrauch der Fahrzeuge sind Schätzungen und können von den
        tatsächlichen Werten abweichen.
      </div>
    </>
  )
}

export default Rechner
