import React from "react"

import { ResponsiveLine, Serie, Datum, SliceTooltip } from "@nivo/line"
import { kostenKummuliert } from "../../helpers/range"
import { Card } from "../card"
import { IAuto } from "./interfaces"

const Tooltip: SliceTooltip = ({ slice }) => (
  <div
    className="bg-white border-cyan"
    style={{
      padding: "8px 10px",
    }}
  >
    <div>Jahr: {slice.points[0].data.x}</div>
    {slice.points.map(point => (
      <div
        key={point.id}
        style={{
          color: point.serieColor,
          padding: "3px 0",
        }}
      >
        <strong>{point.serieId}:</strong> {point.data.yFormatted} €
      </div>
    ))}
  </div>
)

export const ErgebnisChart = ({
  km,
  autos,
}: {
  km: number
  autos: IAuto[]
}) => {
  const data = autos.map<Serie>(auto => {
    return {
      id: auto.name,
      data: kostenKummuliert(km, auto).map<Datum>((kosten, i) => ({
        x: i,
        y: kosten,
      })),
    }
  })

  const yMin =
    data
      .map(x => x.data.map(y => y.y))
      .reduce((acc, val) => acc.concat(val), [])
      .map(x => x.valueOf() as number)
      .reduce((acc, val) => (acc > val ? val : acc)) - 5000

  return (
    <Card title="Vergleichs-Grafik">
      <div style={{ height: "450px" }}>
        <ResponsiveLine
          data={data}
          theme={{
            axis: {
              ticks: {
                text: {
                  fill: "#59a7a7",
                  fontSize: "14px"
                },
              },
              legend: {
                text: {
                  fontSize: "16px"
                }
              }
            },
            legends: {
              text: {
                fontSize: "16px"
              }
            }
          }}
          curve="monotoneX"
          margin={{ top: 10, right: 10, bottom: 45, left: 50 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: yMin,
            max: "auto",
            stacked: false,
            reverse: false,
          }}
          // yFormat="$,.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Jahre",
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: -50,
            legendPosition: "middle",
            format: value => `${Number(value) / 1000} T€`,
          }}
          colors={{ scheme: "paired" }}
          lineWidth={6}
          pointSize={10}
          pointColor={{ from: "color", modifiers: [] }}
          pointBorderWidth={3}
          // enableArea={true}
          areaOpacity={0.25}
          areaBaselineValue="auto"
          useMesh={true}
          enableSlices="x"
          sliceTooltip={Tooltip}
          legends={[
            {
              anchor: "top",
              direction: "column",
              justify: false,
              translateX: -85,
              translateY: 30,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </Card>
  )
}
